.App {
  text-align: center;
}

.nav-tabs .nav-link {
  cursor: pointer;
}

textarea {
  margin-top: 20px;
  padding: 10px;
  font-size: 16px;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.progress {
  height: 20px;
}

table {
  margin-top: 20px;
}
